import { SVGIcon } from '../SVGIcon'
import { theme } from '../../../styles'

export interface IShowMoreButton {
  icon?: string
  iconOrientation?: 'down' | 'left' | 'right' | 'up'
  htmlFor?: string
  id?: string
  onClick?: React.MouseEventHandler
  size?: string
  title?: string
}

export function ShowMoreButton({
  icon = 'plus',
  iconOrientation,
  htmlFor,
  id,
  onClick,
  size = '315px',
  title = 'Voir plus',
}: IShowMoreButton): JSX.Element {
  const RenderComponent = onClick ? 'button' : 'label'
  const renderProps = onClick ? { onClick } : { htmlFor }

  return (
    <>
      <RenderComponent {...renderProps} {...(id ? { id } : {})} className="ShowMoreButton flex">
        <span className="ShowMoreButton__label">{title}</span>
        {icon && (
          <SVGIcon
            primaryColor={theme.cssVars.fontColor}
            name={icon}
            orientation={iconOrientation}
            size={24}
          />
        )}
      </RenderComponent>

      <style jsx>{`
        .ShowMoreButton {
          max-width: ${size};
        }

        :global(#${id}:checked) ~ .ShowMoreButton {
          display: none;
        }
      `}</style>
      <style jsx>{`
        .ShowMoreButton {
          align-items: center;
          background-color: ${theme.cssVars.buttonBackground};
          border-radius: 4px;
          border-style: hidden;
          color: ${theme.cssVars.fontColor};
          cursor: pointer;
          height: 56px;
          justify-content: center;
          margin: auto;
          width: 100%;
        }

        .ShowMoreButton__label {
          font-size: 15px;
          font-weight: 600;
          margin-right: 0.5em;
          padding-top: 2px;
        }
      `}</style>
    </>
  )
}
